<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

 

    <lista-carga v-if="load"></lista-carga>
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">

                <div class="row mb-3">
                    <div class="col">
                        <label class="mr-sm-2">Desde</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='desdex'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='hastax'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                        <button  class="btn btn-md btn-success w-100" @click="obtenerLiquidacion()">Consultar Novedades</button>
                    </div>
                </div>

               <h4 id="leyend">Reporte de novedades desde el <span id="desde"></span> y el <span id="hasta"></span></h4>
               <br>
          


            <div class="table-responsive">
              <table
                id="novedades"
                class="table table-theme table-row v-middle"
              
              >
                <thead>
                  <tr>
                    <th><span class="text-muted">Legajo</span></th>
                    <th><span class="text-muted">Nombre</span></th>
                    <th><span class="text-muted">Responsable</span></th>
                    <th><span class="text-muted">Operación</span></th>
                    <th><span class="text-muted">Sector</span></th>

                    <th><span class="text-muted">Bejerman id</span></th>
                    <th><span class="text-muted">Novedad</span></th>
                  
                   <!-- <th><span class="text-muted">Desde</span></th>
                    <th><span class="text-muted">Hasta</span></th> -->
                    <th><span class="text-muted">Días</span></th>
                    <th><span class="text-muted">Horas</span></th>
                
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="caso in novedades" :key="caso.legajo">
                       

                        <td :id="'key'+caso.fireId">{{ caso.legajo }}</td>
                        <td class="text-sm text-wrap">{{ caso.nombre }}</td>
                        <td class="text-sm text-wrap">{{ caso.responsable }}</td>
                        <td class="text-sm text-wrap">{{ caso.operacion }}</td>
                         <td class="text-sm text-wrap">{{ caso.sector }}</td>
                        <td class="text-sm text-wrap">{{ caso.bejerman_id }}</td>
                        <td class="text-sm text-wrap">{{ caso.novedad }}</td>
                       
                        <!--<td class="text-sm text-wrap">{{ caso.desde }}</td>
                        <td class="text-sm text-wrap">{{ caso.hasta }}</td>-->
                        <td class="text-sm text-wrap">{{ caso.dias }}</td>
                        <td class="text-sm text-wrap">{{ caso.horas }}</td>
                    
                       

                       
                           
                          

                        
                    </tr>
                </tbody>
              </table>

               <div class="alert alert-danger"  role="alert" id="alerta">
                                 Consulta liquidación de novedades ingresando el rango de fechas que corresponda
                                </div>
            </div>
          </div>
        </div>
      </div>
    </div>





<center><h5>VER NOVEDADES AGRUPADAS DE UN ZONAL ESPECIFICO</h5>
</center><br>
      <div class="col-md-12 grid-margin stretch-card">

        <div class="card">
          <div class="card-body">

                <div class="row mb-3">
                    <div class="col">
                        <label class="mr-sm-2">Desde</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='desdexa'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='hastaxa'/>
                    </div>

                     <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Dni</label>
                        <input type="number" class="form-control mb-2 mr-sm-2" id='responsableDni'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                        <button  class="btn btn-md btn-success w-100" @click="reporte()">Ver reporte</button>
                    </div>
                </div>

            
               <br>
          


            <div class="table-responsive">
              <table
                id="reporte"
                class="table table-theme table-row v-middle"
              
              >
              </table>

               <div class="alert alert-danger"  role="alert" id="alerta2">
                                 Consulta liquidación de novedades agrupadas
                                </div>
            </div>
          </div>
        </div>
      </div>
   












  </div>








 <!-- Extra large modal -->


<div class="modal fade observado_modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card-body card-block">
        <div class="row">
          <div class="col-sm-12" style="background-color: #f9e8b7;padding: 10px;">
            
              

              <div class="form-group">
                    <label for="exampleFormControlTextarea1">Escribe la observación/detalle que explique el motivo del estado</label>
                    <textarea class="form-control" id="observacion" rows="5"></textarea>
                  </div>
           
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6"><button class="btn btn-block btn-success" v-on:click="guardaObservacion()">Guardar</button></div>
              <div class="col-sm-6"><button  class="btn btn-block btn-danger" data-dismiss="modal" id="cerrar_modal">Cancelar</button></div>
            </div>  
          </div>
        </div>
      </div>

        <div id="historial"></div>


    </div>
  </div>
</div>

</template>

<script>
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
//require("../../../public/assets/css/data-table-estilos-propios.css");
const userData = JSON.parse(sessionStorage.getItem('userData'));
const personalData = JSON.parse(sessionStorage.getItem('personal'));

import firebase from "firebase/app";
import "firebase/database";

import ListaCarga from '../ListaCarga';

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
  name: "novedades",
  components: {ListaCarga},
  data: () => ({
    breadcrumbA: "Inicio",
    breadcrumbB: "Novedades de Operaciones",
    descripcion: "Listado de novedades en el personal",
    novedades: [],
   
    load: true,

  }),
  methods: {

    b64toBlob(b64, onsuccess, onerror) {
    var img = new Image();

    img.onerror = onerror;

    img.onload = function onload() {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(onsuccess);
    };

    img.src = b64;
},

openWindow(key) {





$.ajax({
            type: 'get',
            url: 'https://aplicativos.firebaseio.com/novedades_usuarios_test/'+key+'.json?print=pretty',
            contentType: "application/json; charset=utf-8",
            traditional: true,
            success: function (datos) {
             

           

          


fetch(datos.certificado)
.then(response => response.blob())
.then(function(myBlob) {
  var objectURL = URL.createObjectURL(myBlob);
  window.open(objectURL, '_blank');
});


            }

            });




  


  

 
},



    isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },

obtenerLiquidacion(){
  this.load = true;
 this.getNovedad();
},
reporte(){


$("#busqueda").hide();
$("#reload").show();
$("#notificaciones").show();
  //$("#auscencias_data").html("");
  //$("#loading_b").show();




    var hoy = new Date();
    var mes = hoy.getMonth() + 1;
    var numeroMes = mes < 10 ? "0" + mes : mes;
    var dia = hoy.getDate();
    dia = dia < 10 ? "0" + dia : dia;
    var anio = hoy.getFullYear();
   

    var anterior  = new Date(hoy.setMonth(hoy.getMonth()-1));
    var mes_anterior = anterior.getMonth() + 1;
    var numeroMes_a = mes_anterior < 10 ? "0" + mes_anterior : mes_anterior;
    var dia_a = anterior.getDate();
    dia_a = dia_a < 10 ? "0" + dia_a : dia_a;
    var anio_a = anterior.getFullYear();

    //var mes_act = anio+"-"+numeroMes+"-15";

    //var mes_ant = anio_a+"-"+numeroMes_a+"-15";


    var mes_anta = $("#desdexa").val();

    var mes_acta = $("#hastaxa").val();


  var novedades = [];
  var nove = [];
  let reporte ="";

  console.log("https://auth.foodservice.com.ar/?type=novedades_reporte_zonal_dni&desde="+mes_anta+"&hasta="+mes_acta+"&dni="+$('#responsableDni').val()+"&access_token=1234567")
  $.get( "https://auth.foodservice.com.ar/?type=novedades_reporte_zonal_dni&desde="+mes_anta+"&hasta="+mes_acta+"&dni="+$('#responsableDni').val()+"&access_token=1234567", function(data) {
      

      var novedad = JSON.parse(data).novedades;
      var lista = JSON.parse(data).lista;

      //console.log(novedad['AIRALA MAURO AGUSTIN']['Sabados NT']['dias'])
        


        //$("#reporte").append("<tr>");
        //$("#reporte").append("<td>Nombre</td>");

        nove.push({"title":"Nombre"})
        nove.push({"title":"Legajo"})
        $.each(JSON.parse(data).lista,function(clave,valor){
          nove.push({
            "title":clave,
            "render": function (data, type, row) {
              if(type==='export'){
                if(typeof data == 'string') {
                  let filteredData = "";
                  filteredData = data.replace(' hs', '' );
                  filteredData = filteredData.replace(' dias', '' );
                  return filteredData;
                }else {
                  return data;
                }
              }else {
                return data;
              }
              // return data;
              //   return type === 'export' ?
              //       data.replaceAll('DiasHs', '' ) : data;
              //       // data.replaceAll('Hs', '' ) : data;
            }
          })
          // console.log(clave,valor);
        });

        
          //$("#reporte").append("</tr>");

          
      
        
var loop = [];
var count = 0;
        // busca novedades por usuario  
        $.each(JSON.parse(data).novedades,function(key,value){
          // console.log('novedad',key,Object.keys(value)[0]);
          
        
            //$("#reporte").append("<tr>");
            //$("#reporte").append("<td>"+key+"</td>");
            loop = [];
            loop.push(key);
            loop.push(novedad[key]['legajo']);

            $.each(JSON.parse(data).lista,function(k,v){
                
              if (novedad[key][k]!=undefined) {
                if (novedad[key][k]['horas']!=undefined) {
                  loop.push(`${parseInt(novedad[key][k]['horas'])}`); // Se elimina ' Hs'
                  //loop+="<td>"+novedad[key][k]['horas']+"</td>";
                  // console.log('horas',novedad[key][k]);

                  console.log(key,k);
                }else{
                  loop.push(`${novedad[key][k]['dias']}`); // Se elimina'Dias'
                  // console.log('dias',novedad[key][k]);
                  //$("#reporte").append("<td>"+novedad[key][k]['dias']+"</td>");
                  console.log(key,k);
                  // nove[key]['title'] += ' días';
                }
                
              }else{
                loop.push(0);
                //$("#reporte").append("<td>-</td>");
              }
              
            });
          
          count++;
          // console.log(count)

          //t.row.add().draw( false );
          //$("#reporte").append("</tr>");
        
novedades.push(loop)
        });



console.log(nove)

 $('#reporte').DataTable( {
       pageLength: 10,
        dom: 'Bfrtip',
    buttons: [
        'copy', {
                extend: 'excelHtml5',
                exportOptions: { orthogonal: 'export' }
            }, 'pdf'
    ],
        data: novedades,
        columns: nove
    } );

    }).done(function() {


  
      
  
    $("#alerta2").hide();
      
  
  
  
  
  


    }).fail(function() {
          alert( "error" );
  })





},
  getNovedad(){

    
    var hoy = new Date();
    var mes = hoy.getMonth() + 1;
    var numeroMes = mes < 10 ? "0" + mes : mes;
    var dia = hoy.getDate();
    dia = dia < 10 ? "0" + dia : dia;
    var anio = hoy.getFullYear();
   

    var anterior  = new Date(hoy.setMonth(hoy.getMonth()-1));
    var mes_anterior = anterior.getMonth() + 1;
    var numeroMes_a = mes_anterior < 10 ? "0" + mes_anterior : mes_anterior;
    var dia_a = anterior.getDate();
    dia_a = dia_a < 10 ? "0" + dia_a : dia_a;
    var anio_a = anterior.getFullYear();

    //var mes_act = anio+"-"+numeroMes+"-15";

    //var mes_ant = anio_a+"-"+numeroMes_a+"-15";


    var mes_ant = $("#desdex").val();

    var mes_act = $("#hastax").val();



const obtenerNombreApellidoPorLegNumero =(legNumero) => {
  // Convertimos el objeto a un array con Object.values
  const personas = Object.values(personalData);
  
  // Buscamos la persona cuyo leg_numero coincida con el valor pasado
  const personaEncontrada = personas.find(persona => persona.leg_numero == legNumero);
  
  if (personaEncontrada) {
    // Retornamos el apellido y nombre, usando trim() para limpiar posibles espacios extra
    return personaEncontrada.leg_apellido.trim()+" "+personaEncontrada.leg_nombre.trim()
  }
  
  // Si no se encontró, retornamos null o lo que necesites
  return null;
}




     axios.get("https://auth.foodservice.com.ar/?type=novedades_reporte&desde="+mes_ant+"&hasta="+mes_act+"&access_token=1234567")
      .then((response) => {
            var vue = this;
            var obs = 0;







            $.each(response.data, (ids, items) => {

              $.each(items, (id, item) => {




                let caso = item;


               
                var certif_u = '-';
                if (caso != null) {

             

                const SumarTiempos = ()=>{
  
                  var input1 = document.getElementById('time1');
                  var input2 = document.getElementById('time2');
                  var strMsg = '';
                  
                  var date1 = input1.valueAsDate;
                  var date2 = input2.valueAsDate;
                  
                  var s = (date1.getTime() + date2.getTime());
                  
                  var ms = s % 1000;
                  s = (s - ms) / 1000;
                  var secs = s % 60;
                  s = (s - secs) / 60;
                  var mins = s % 60;
                  var hrs = (s - mins) / 60;
                  
                  strMsg = hrs + ':' + mins + ':' + secs;
                  
                  return strMsg;
              }

                   
         
                    

                const reemplazarIndefinido = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "-"
                    }
                    return string
                }


                            var fechaInicio = new Date(mes_act).getTime();
                            var fechaFin    = new Date(caso.hasta).getTime();
                            var dias = caso.dias

                            if (fechaFin>fechaInicio) {


                                var diff = fechaFin - fechaInicio;

                                var diferencia = diff/(1000*60*60*24);

                                if ( caso.bejerman_id == 28) {
                                  dias = dias;
                                }else{
                                  dias = dias-diferencia;
                                }

                                


                            }




var bejermanid = caso.bejerman_id;
var concepto = caso.novedad;

if (localStorage.getItem(caso.legajo) == "65" || localStorage.getItem(caso.legajo) == "77" || localStorage.getItem(caso.legajo) == "78" || localStorage.getItem(caso.legajo) == "79" || localStorage.getItem(caso.legajo)=="80" || localStorage.getItem(caso.legajo) == "81" || localStorage.getItem(caso.legajo) == "82") {
  
          if (bejermanid==15||bejermanid==173) {

            bejermanid = 16;
            concepto = "Reempl. Transit. ART 12 (JR)";

          }
          
}


//console.log(caso.nombre+" === "+bejermanid)
                           
                       if (bejermanid==6||bejermanid==20||bejermanid==21||bejermanid==24||bejermanid==25||bejermanid==28||bejermanid==38||bejermanid==100||bejermanid==101||bejermanid==118||bejermanid==122||bejermanid==232||bejermanid==267||bejermanid==102||bejermanid==560||bejermanid==7||bejermanid=="F"||bejermanid=="PM"||bejermanid==31||bejermanid==79||bejermanid==22||bejermanid==27||bejermanid==119||bejermanid==73||bejermanid==159||bejermanid==96) {


var sector ="-";



                        if (caso.legajo!=undefined) {



                          var texto = caso.operacion;

// Verificar si texto no es null o undefined
if (texto && texto.includes("/PLANTA")) {
    sector = "PLANTA";
} else {
    sector = "OPERACION";
}
                                 var ho = caso.horas;
                                  vue.novedades.push({
                                      legajo: caso.legajo,
                                      nombre: obtenerNombreApellidoPorLegNumero(caso.legajo),
                                      responsable: caso.responsable,
                                      operacion: caso.operacion,
                                      sector: sector,
                                      bejerman_id: bejermanid,
                                      novedad: concepto.replace("Operartiva","Operativa"),
                                      desde: caso.desde,
                                      hasta: caso.hasta,
                                      dias: dias,
                                      horas:'-'

                                      
                                  });
                                }

                           }else{

if (caso.legajo!=undefined) {

  var texto = caso.operacion;

// Verificar si texto no es null o undefined
if (texto && texto.includes("/PLANTA")) {
    sector = "PLANTA";
} else {
    sector = "OPERACION";
}
                                var ho = caso.horas;
                                  vue.novedades.push({
                                      legajo: caso.legajo,
                                      nombre: obtenerNombreApellidoPorLegNumero(caso.legajo),
                                      responsable: caso.responsable,
                                      operacion: caso.operacion,
                                      sector: sector,
                                      bejerman_id: bejermanid,
                                       novedad: concepto.replace("Operartiva","Operativa"),
                                      desde: caso.desde,
                                      hasta: caso.hasta,
                                      dias: '-',
                                      horas:reemplazarIndefinido(ho.toString().replace(/^0+/, ''))

                                      
                                  });

}

                           }


                       
               
                }
            });

              });








            this.load = false;
      })
      .then(()=>{

               $("#desde").html(mes_ant);
               $("#hasta").html(mes_act);
               $("#alerta").hide();
            $('#novedades').dataTable({

            "language": {
            "decimal": ",",
            "thousands": ".",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
            "infoPostFix": "",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "loadingRecords": "Cargando...",
            "lengthMenu": "Mostrar _MENU_ registros",
            "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": "Siguiente",
            "previous": "Anterior"
            },
            "processing": "Procesando...",
            "search": "Buscar:",
            "searchPlaceholder": "Término de búsqueda",
            "zeroRecords": "No se encontraron resultados",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "aria": {
            "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
            },
            },
            dom: 'Bfrtip',
            "scrollX": false, 
            "search": {regex:true},
           
            responsive: false,
            nowrap: false,
             "initComplete":function( settings, json){

              setTimeout(function(){
                 console.log("completo");
                $('#novedades').DataTable().order([7, 'desc']).draw();
              },2000)

             
            // call your function here
        },
          buttons: [
              'copy', 'excel', 'pdf', 'print'
          ],
            })



 const diasEntreFechas = (desde, hasta) => {

                            var dia_actual = desde;
                            var fechas = [];
                            while (dia_actual.isSameOrBefore(hasta)) {
                              fechas.push(dia_actual.format('YYYY-MM-DD'));
                              dia_actual.add(1, 'days');
                            }
                            return fechas;
                        };

 $("#buscar").click(function(){
                          
                        console.log($("#desde").val()+"hasta "+$("#hasta").val())
                        var filtro = "";
                        var desde = moment($("#desde").val());
                        var hasta = moment($("#hasta").val());
                        var results = diasEntreFechas(desde, hasta);
                       

                        $.each(results,function(key,value){
                          filtro+=value+"|";
                        })
                        var table = $('#novedades').DataTable();
                        console.log(filtro.substring(0, filtro.length - 1));
                        table.column(10).search(filtro.substring(0, filtro.length - 1), true, false ).draw();


                        })


      })

      },      
  

  /*  cambiarStatus(caso){
        let statusMensaje = (caso.statusMensaje == "Resuelto") ? "En espera" : "Resuelto"; 
        console.log('novedades_usuarios_test/' + caso.fireId);
        firebase.database().ref('novedades_usuarios_test/' + caso.fireId).update({
            status: statusMensaje,
        })
        .then(()=>{
            caso.status = !caso.status;
            caso.statusMensaje = statusMensaje;
        })
        .catch(e => {
            console.log(e);
        });
    }
  },*/

  cambiarStatus(event, caso){

            if (event.target.value=="Observado"||event.target.value=="Rechazado") {

            window.$('.modal').modal();
            localStorage.setItem("status_id",caso.fireId);
            localStorage.setItem("status_sms",event.target.value);

             caso.status = event.target.value;
             caso.statusMensaje = event.target.value;
            

            $("#historial").empty();
            }else{
         
                firebase.database().ref('novedades_usuarios_test/' + caso.fireId).update({
                    status: event.target.value,
                    obs:0

                })
                .then(()=>{
                    caso.status = event.target.value;
                    caso.statusMensaje = event.target.value;
                    $("#key"+caso.fireId).hide();
                })
                .catch(e => {
                    console.log(e);
                });
            }    
      },




      mensajes(){


            window.$('.modal').modal();
            $("#historial").empty();

                 axios.get("https://aplicativos.firebaseio.com/novedades_usuarios_test.json?orderBy=%22$key%22&equalTo=%22"+localStorage.getItem('status_id')+"%22&print=pretty")
                .then((response) => {

                    $.each(response.data,function(key,value){
                        $.each(value.observacion,function(k,v){
                            $("#historial").prepend("<center><div style='width:90%;padding:5px;border:1px solid #d2cbcb;margin-top:5px;margin-bottom:5px;margin:5px;text-align: left;padding-left: 15px;'><span style='color:#716868;'><i>"+v.timestamp+"</i></span><br><b>"+v.nombre+":</b> "+v.detalle+"<br></div></center>");
                        })
                    })


                });
                     


          
      },

      guardaObservacion(){



        const addZero =(i) => {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        }

                var h = new Date();
                var hora = addZero(h.getHours());
                var minutos = ("0" + h.getMinutes()).substr(-2);
                var segundos = ("0" + h.getSeconds()).substr(-2);
                var horaActual = hora +':'+ minutos +':'+ segundos;

                var today = new Date();
                var m = today.getMonth() + 1;
                var mes = m < 10 ? "0" + m : m;
                var dia = today.getDate();
                dia = dia < 10 ? "0" + dia : dia;
                var fechaActual = today.getFullYear() + "-" + mes + "-" + dia;
                var timestamp = fechaActual + " " + horaActual;


           
                firebase.database().ref('novedades_usuarios_test/' + localStorage.getItem('status_id')+'/observacion').push({
                    detalle: $("#observacion").val(),
                    timestamp:timestamp,
                    nombre:userData.leg_nombre+' '+userData.leg_apellido

                })
                .then(()=>{


                        firebase.database().ref('novedades_usuarios_test/' + localStorage.getItem('status_id')).update({
                          status: localStorage.getItem('status_sms'),
                           obs: 0

                        })
                        .then(()=>{
                          $(".comentario #key"+localStorage.getItem('status_id')).hide();
                        })
                        .catch(e => {
                          console.log(e);
                        });

                
                  $("#observacion").val("");

                    Swal.fire(
                                      "Observación creada correctamente!",
                                      "Su observación fue enviada al zonal que corresponda",
                                      "success"
                                    );

                                      setTimeout(function(){
                                          $("#cerrar_modal").click();
                                          console.log("ocultar noti")
                                          console.log(localStorage.getItem('status_id'))
                                            $(".comentario #key"+localStorage.getItem('status_id')).hide();
                                          

                                           setTimeout(function(){
                                         
                                                localStorage.removeItem('status_id');
                                            
                                            },3000);
                                            
                                      },1000);



                })
                .catch(e => {
                    Swal.fire(
                                      "Ups!, Ocurrio un error",
                                      "Revise su conexión a internet y vuelva a intentar",
                                      "error"
                                    );

                                      setTimeout(function(){
                                          $("#cerrar_modal").click();
                                      },1000);
                });




              
      },

      

    },
  async mounted() {
     

            
            this.load = false;



  }
}
</script>
