<template>
  <div class="page-content">
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ breadcrumbB }}
        </li>
      </ol>
    </nav>

    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
       

              <h6 class="card-title">Gastos cargados por los zonales de los gastos de su personal a cargo</h6>
            <p class="card-description">
              <span style="margin-left:5px;margin-top:5px;" class="badge badge-warning">En espera</span>
              <span style="margin-left:5px;margin-top:5px;" class="badge badge-success">Aprobado</span>
              <span style="margin-left:5px;margin-top:5px;" class="badge badge-danger">Rechazado</span>
            </p>
            <HR/>

          

          </div>
        </div>
      </div>
    </div>

    <lista-carga v-if="load"></lista-carga>
    <div class="row" v-else>
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">



                <div class="row mb-3">
                    <div class="col">
                        <label class="mr-sm-2">Desde</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='desde'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2" for="inlineFormCustomSelect">Hasta</label>
                        <input type="date" class="form-control mb-2 mr-sm-2" id='hasta'/>
                    </div>
                    <div class="col">
                        <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                        <button  class="btn btn-md btn-success w-100" id="buscar">Consultar Gastos</button>
                    </div>
                </div>
          


            <div class="table-responsive">
              <table
                id="novedades_hrbp"
                class="table table-theme table-row v-middle"
                data-plugin="dataTable"
              >
                <thead>
                  <tr>
                    <th><span class="text-muted">ID</span></th>
                    <th><span class="text-muted">legajo</span></th>
                    <th><span class="text-muted">Status</span></th>
                    
                    <th><span class="text-muted">Nombre</span></th>
                    <th><span class="text-muted">Operación</span></th>

                   <!-- <th><span class="text-muted">Bejerman id</span></th>
                    <th><span class="text-muted">Novedad</span></th>-->
                    <th><span class="text-muted">Detalle</span></th>
                    <th><span class="text-muted">Gasto</span></th>
                    
                    <!--<th><span class="text-muted">Creado</span></th>
                    <th><span class="text-muted">Fecha Novedad</span></th>-->
                    <th><span class="text-muted">Fecha</span></th>
                    <!--<th><span class="text-muted">Hasta</span></th>-->
                    <th><span class="text-muted">Días</span></th>
                    <th><span class="text-muted">Categoria</span></th>
                    <th><span class="text-muted">Responsable</span></th>
                    <th><span class="text-muted">Comprobante</span></th>
                    
                    <th><span class="text-muted">Accion</span></th>
                    <th><span class="text-muted">Auditoría</span></th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-for="caso in novedades" :key="caso.legajo">
                      <td :id="'key'+caso.fireId">{{ caso.id }}</td>
                      <td :id="'key'+caso.fireId">{{ caso.legajo }}</td>
                        <td v-if="caso.status=='En espera'">
                            <span  class="badge text-uppercase badge-warning">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        <td v-if="caso.status=='Aprobado'">
                            <span  class="badge text-uppercase badge-success">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                      

                        <td v-if="caso.status=='Rechazado'">
                            <span  class="badge text-uppercase badge-danger">
                              {{caso.statusMensaje}}
                            </span>
                        </td>

                        
                        <td class="text-sm text-wrap">{{ caso.nombre }}</td>
                        <td class="text-sm text-wrap">{{ caso.operacion_raiz }}</td>
                       <!-- <td class="text-sm text-wrap">{{ caso.bejerman_id }}</td>
                        <td class="text-sm text-wrap">{{ caso.novedad }}</td>-->
                        <td class="text-sm text-wrap">{{ caso.detalle }}</td>
                        <td class="text-sm text-wrap">{{ caso.gastos }}</td>
                        
                        <!--<td class="text-sm text-wrap">{{ caso.fecha }}</td>
                        <td class="text-sm text-wrap">{{ caso.fecha_novedad }}</td>-->
                        <td class="text-sm text-wrap">{{ caso.desde }}</td>
                       <!-- <td class="text-sm text-wrap">{{ caso.hasta }}</td>-->
                        <td class="text-sm text-wrap">{{ caso.dias }}</td>
                        <td class="text-sm text-wrap">{{ caso.categoria }}</td>
                        <td class="text-sm text-wrap">{{ caso.responsable }}</td>
                        <td class="text-sm text-wrap" v-if="caso.certificado!='-'"><button @click="openWindow(caso.certificado)" class=" btn btn-warning">Ver</button></td>
                        <td class="text-sm text-wrap" v-else>-</td>

                       
                        <td>
                           
                          

                          <select v-if="isEditor('Novedades')==true"  class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)">
                                <option value="En espera" :selected="caso.status == 'En espera'">En espera</option>
                                <option value="Aprobado"  :selected="caso.status == 'Aprobado'">Aprobado</option>
                            
                                <option value="Rechazado" :selected="caso.status == 'Rechazado'">Rechazado</option>
                                
                            </select>

                            <select v-else class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)" disabled="true">
                                 <option value="Aprobado" :selected="caso.status == 'Aprobado'">Aprobado</option>
                      
                                <option value="Rechazado" :selected="caso.status == 'Rechazado'">Rechazado</option>
                            </select>
                        </td>

                        <td>
                           
                          

                          <select v-if="isEditor('Novedades')==true"  class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)">
                                <option value="">Selecciona Acción</option>
                               
                                <option value="Editar" :selected="caso.status == 'Observado'">Editar datos</option>
                               
                                
                            </select>

                            <select v-else class="custom-select mr-3 resolucionSelect" @change="cambiarStatus($event, caso)" disabled="true">
                                
                                <option value="Editar">Editar datos</option>
                               
                            </select>
                        </td>


                    </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>







<!-- MODAL DE AUDITORIA  -->


<div class="modal fade editar_modal" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="card-body card-block">
        <div class="row">
          <div class="col-sm-12" style="background-color: #f9e8b7;padding: 10px;">
            
            <b> NOVEDAD DE <span id="editor_nombre"></span> </b><br>
              
<table width="100%">
    

<td colspan="3" width="70%">
   <div class="form-group formulario" data-legajo="" data-empresa="">
      <label for="exampleFormControlInput1">*Novedad * Tenga en cuenta que si modifica la novedad modificara la liquidación final</label><br>
      <select data-empresa="" data-legajos="" id="editor_novedades" class="js-example-basic novedadsel" style="width:100%;width: 100%;border: 1px solid #9b9b9b;color: #2e2929;">
        <option value="0">-- BUSCA Y SELECCIONA NOVEDAD --</option>
     <option data-condicion="y" data-horas="n" value="20">Enfermedad</option>
   <option data-condicion="y" data-horas="n" value="21">Accidente</option>
   <option data-condicion="y" data-horas="n" value="100">Ausencia Injustificada</option>
   <option data-condicion="y" data-horas="n" value="38">Lic. vacuna Covid</option>
   <option data-condicion="y" data-horas="n" value="129">Lic. Maternidad</option>
   <option data-condicion="y" data-horas="n" value="25">Lic. Examen</option>
   <option data-condicion="y" data-horas="n" value="116">Lic. Excedencia</option>
   <option data-condicion="y" data-horas="n" value="24">Lic. Fallecimiento (Conyuge e Hijos)</option>
   <option data-condicion="y" data-horas="n" value="24">Lic. Fallecimiento (Padre/Madre)</option>
   <option data-condicion="y" data-horas="n" value="24">Lic. Fallecimiento (Hermanos)</option>
   <option data-condicion="y" data-horas="n" value="24">Lic. Fallecimiento (Abuelos o Suegros)</option>
   <option data-condicion="y" data-horas="n" value="22">Lic. Matrimonio</option>
   <option data-condicion="y" data-horas="n" value="26">Lic. Mudanza</option>
   <option data-condicion="y" data-horas="n" value="23">Lic. Nacimiento</option>
   <option data-condicion="y" data-horas="n" value="28">Lic. Vacaciones</option>
   <option data-condicion="y" data-horas="n" value="101">Suspension Disciplinaria</option>
   <option data-condicion="n" data-horas="n" value="122">Suspension Operartiva</option>
   <option data-condicion="c" data-horas="n" value="560">Suspension Operartiva ( SOLO CORDOBA )</option>
   <option data-condicion="c" data-horas="n" value="139">Lic. maternidad ( SOLO CORDOBA )</option>
   <option data-condicion="m" data-horas="y" value="19">Adic. horas nocturnas</option>
   <option data-condicion="m" data-horas="n" value="232">Adic. reempl. categoria transitorio</option>
   <option data-condicion="y" data-horas="n" value="27">Lic. donac. sangre</option>
   <option data-condicion="y" data-horas="n" value="31">Lic. tram. jud</option>
   <option data-condicion="m" data-horas="y" value="15">Hs. extras 50%</option>
   <option data-condicion="m" data-horas="n" value="213">Quimicos DIA DEL QUIMICO ( SOLO LINDE )</option>
   <option data-condicion="m" data-horas="y" value="119">Llegadas tarde</option>
   <option data-condicion="n" data-horas="n" value="118">Sabados NT</option>
   <option data-condicion="m" data-horas="y" value="173">Hs. extras 100%</option>
   <option data-condicion="m" data-horas="n" value="6">Feriado</option>
   <option data-condicion="m" data-horas="n" value="267">Feriado Supervisor</option>
   <option data-condicion="m" data-horas="n" value="7">Feriado 2/8 ART31 CCT</option>
   <option data-condicion="m" data-horas="n" value="212">Quimicos Feriado Trabajado ( SOLO LINDE )</option>
   <option data-condicion="x" data-horas="n" value="00">Certificado de vacunación</option>
   <option data-condicion="m" data-horas="n" value="B01">Paro de transporte 07/07/2023</option>
       
      </select>
      
   </div>
   
   <br>

<div id="horas-8428">
  <div class="form-group">
    <label for="exampleFormControlInput1">Cantidad de horas</label>
    <input id="editor_horas" type="time" style="width:100%;">
    <br>
  </div>
</div>


   <br>
   <table width="100%">
      <tbody>
         <tr>
            <td>Desde *</td>
            <td>Hasta *</td>
         </tr>
         <tr>
            <td><input style="width: 50%;" value="" type="date" id="editor_inix"  min=""></td>
            <td><input style="width: 50%;" value="" type="date" id="editor_finix" min=""></td>
         </tr>
      </tbody>
   </table>

<input id="editor_fire" type="hidden" style="width:100%;">
<br>
   <div class="form-group"><label for="exampleFormControlTextarea1">Detalle</label><br><textarea style="width: 100%;border: 1px solid #9b9b9b;color: #2e2929;" class="form-control" id="editor_detalle" rows="3" width="100%"></textarea></div>
</td>


</table>
           
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-sm-6"><button class="btn btn-block btn-success" v-on:click="guarda_auditoria()">Guardar</button></div>
              <div class="col-sm-6"><button  class="btn btn-block btn-danger" data-dismiss="modal" id="cerrar_modal">Cancelar</button></div>
            </div>  
          </div>
        </div>
      </div>

        

    </div>
  </div>
</div>


</template>

<script>
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
require("datatables.net");
require("datatables.net-buttons/js/buttons.colVis.js")();
require("datatables.net-buttons/js/buttons.html5.js")();
require("datatables.net-buttons/js/buttons.flash.js")();
require("datatables.net-buttons/js/buttons.print.js")();
require("../../../public/assets/css/data-table-estilos-propios.css");
const userData = JSON.parse(sessionStorage.getItem('userData'));
const personalData = JSON.parse(sessionStorage.getItem('personal'));

import firebase from "firebase/app";
import "firebase/database";

import ListaCarga from '../ListaCarga';

const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
  name: "novedades",
  components: {ListaCarga},
  data: () => ({
    breadcrumbA: "Inicio",
    breadcrumbB: "Novedades de Gastos a pagar",
    descripcion: "Listado de novedades de gastos a pagar en el personal ",
    novedades: [],
    zonales: [],
    load: true,

  }),
  methods: {

    b64toBlob(b64, onsuccess, onerror) {
    var img = new Image();

    img.onerror = onerror;

    img.onload = function onload() {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(onsuccess);
    };

    img.src = b64;
},

openWindow(link) {
window.open(link, "_blank");
 
},

    isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
           
            return status;
          }else{
            
            return false;
          }
        },


  getNovedad(){

  let hoy = new Date();
  let dieciseisDias = 1000 * 60 * 60 * 24 * 60;

  let resta = hoy.getTime() - dieciseisDias;
  let date = new Date(resta);

  let day = `${(date.getDate())}`.padStart(2,'0');
  let month = `${(date.getMonth()+1)}`.padStart(2,'0');
  let year = date.getFullYear();


  const hashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash |= 0; // Convierte a entero de 32 bits
    }
    return hash;
}




const obtenerNombreApellidoPorLegNumero =(legNumero) => {
  // Convertimos el objeto a un array con Object.values
  const personas = Object.values(personalData);
  
  // Buscamos la persona cuyo leg_numero coincida con el valor pasado
  const personaEncontrada = personas.find(persona => persona.leg_numero == legNumero);
  console.log("legajo encontrado")
  console.log(personaEncontrada)
  if (personaEncontrada) {
    // Retornamos el apellido y nombre, usando trim() para limpiar posibles espacios extra
    return personaEncontrada.leg_apellido.trim()+" "+personaEncontrada.leg_nombre.trim()
  }
  
  // Si no se encontró, retornamos null o lo que necesites
  return null;
}


const toFourDigits = (str) => {
    const hash = Math.abs(hashCode(str));
    return Number(('0000' + hash).slice(-5));
}

     axios.get("https://aplicativos.firebaseio.com/novedades_usuarios_test_hrbp.json?orderBy=%22bejerman_id%22&equalTo=%22B1982%22&limitToLast=5000&print=pretty")
      .then((response) => {
            var vue = this;
            var obs = 0;
            $.each(response.data, (id, item) => {
                let caso = item;
                var cert_c = '-';
                var certif_u = '-';
                if (caso != null) {

                  if (caso.observacion!=undefined) {
                   
                    obs = 1;
                  }else{
                    obs = 0;
                  }
                  

                    if (caso.certificado!=undefined) {
                
             
                       cert_c = caso.certificado;

                     }

                     if (item.certificado_update!=undefined) {
                        
                        $.each(item.certificado_update, function (clab, itb) {

                          certif_u = itb.certificado;
                          


                       });

                       cert_c = certif_u;
                      }
            
                  
                      if (caso.novedad!="Sin novedad") {





                              var fechaInicio = new Date(caso.desde).getTime();
                              var fechaFin    = new Date(caso.hasta).getTime();

                              var diff = fechaFin - fechaInicio;
                                  var bejerman = "-";
                                if (caso.bejerman_id) {
                                  bejerman = caso.bejerman_id;
                                }

                                

                            if (bejerman=="B1982") {
                                vue.novedades.push({
                                    fireId: id,
                                    id:toFourDigits(id),
                                    desde: caso.desde,
                                    categoria:localStorage.getItem("cat-"+localStorage.getItem(caso.legajo)),
                                    detalle: caso.detalle,
                                    fecha: caso.fecha,
                                    fecha_novedad: caso.fecha_novedad,
                                    hasta: caso.hasta,
                                    certificado:cert_c,
                                    legajo: caso.legajo,
                                    gastos:"$"+caso.gastos,
                                    nombre: obtenerNombreApellidoPorLegNumero(caso.legajo),
                                    novedad: caso.novedad,
                                    bejerman_id: bejerman,
                                    operacion_raiz: caso.operacion_raiz,
                                    responsable: caso.responsable,
                                    responsable_dni: caso.responsable_dni,
                                    status: caso.status,
                                    observacion:obs,
                                    statusMensaje: caso.status,
                                    dias:(diff/(1000*60*60*24))+1
                                });
                              }


                  
                  }
                }
            });



            this.load = false;
      })
      .then(()=>{



            $('#novedades_hrbp').dataTable({
            "language": {
            "decimal": ",",
            "thousands": ".",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
            "infoPostFix": "",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "loadingRecords": "Cargando...",
            "lengthMenu": "Mostrar _MENU_ registros",
            "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": "Siguiente",
            "previous": "Anterior"
            },
            "processing": "Procesando...",
            "search": "Buscar:",
            "searchPlaceholder": "Término de búsqueda",
            "zeroRecords": "No se encontraron resultados",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "aria": {
            "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
            },
            },
            "scrollX": false, 
            "search": {regex:true},
            dom: 'Bfrtip',
            responsive: false,
            nowrap: false,
            buttons: [
            'copy',
            'excel',
            'csv',
            {
                  extend: 'print',
                  text: 'Imprimir Gastos filtrados',
                  className: 'imprimir',
                  filename: 'Gastos_pagados', // Nombre personalizado del archivo
                  title: 'Gastos Pagados', // Título en la cabecera del Excel
                  exportOptions: {
                      columns: [1, 2, 3,5,6,8,9] // Especifica aquí los índices de las columnas que deseas exportar
                  }
              },
            {
                text: 'En espera',
                className: 'btn btn-warning',
                action: function ( e, dt, node, config ) {
                  console.log("En espera")
                  var table = $('#novedades_hrbp').DataTable();
                     table.columns( 2 ).search( "En espera" ).draw();
                }
            },
             
             {
                text: 'Rechazado',
                className: 'btn btn-warning',
                action: function ( e, dt, node, config ) {
                   console.log("Rechazado")
                  var table = $('#novedades_hrbp').DataTable();
                    table.columns( 2 ).search( "Rechazado" ).draw();
                }
            }, {
                text: 'Aprobado',
                className: 'btn btn-warning',
                action: function ( e, dt, node, config ) {
                  console.log("Rechazado")
                  var table = $('#novedades_hrbp').DataTable();
                     table.columns( 2 ).search( "Aprobado" ).draw();
                }
            },
             {
                text: 'Todos',
                className: 'btn btn-warning',
                action: function ( e, dt, node, config ) {
                   console.log("Todos")
                  var table = $('#novedades_hrbp').DataTable();
                    table.columns( 2 ).search( "" ).draw();
                    table.columns( 6 ).search( "" ).draw();
                }
            }
            ]
            });


 const diasEntreFechas = (desde, hasta) => {

                            var dia_actual = desde;
                            var fechas = [];
                            while (dia_actual.isSameOrBefore(hasta)) {
                              fechas.push(dia_actual.format('YYYY-MM-DD'));
                              dia_actual.add(1, 'days');
                            }
                            return fechas;
                        };

                      $("#buscar").click(function(){
                          
                        // console.log($("#desde").val()+"hasta "+$("#hasta").val())
                        
                        var filtro = "";
                        var desde = moment($("#desde").val());
                        var hasta = moment($("#hasta").val());
                        var results = diasEntreFechas(desde, hasta);
                       

                        $.each(results,function(key,value){ filtro+=value+"|"; })
                        
                        var table = $('#novedades').DataTable();
                        table.column(9).search(filtro.substring(0, filtro.length - 1), true, false).draw();
                      })


      })

      },      
  

  /*  cambiarStatus(caso){
        let statusMensaje = (caso.statusMensaje == "Resuelto") ? "En espera" : "Resuelto"; 
        console.log('novedades_usuarios_test_hrbp/' + caso.fireId);
        firebase.database().ref('novedades_usuarios_test_hrbp/' + caso.fireId).update({
            status: statusMensaje,
        })
        .then(()=>{
            caso.status = !caso.status;
            caso.statusMensaje = statusMensaje;
        })
        .catch(e => {
            console.log(e);
        });
    }
  },*/

  cambiarStatus(event, caso){

            if (event.target.value=="Observado"||event.target.value=="Rechazado"||event.target.value=="En espera") {

            //window.$('.modal').modal();
            localStorage.setItem("status_id",caso.fireId);
            localStorage.setItem("status_sms",event.target.value);

            

             firebase.database().ref('novedades_usuarios_test_hrbp/' + caso.fireId).update({
                          status: event.target.value,

                      })
                      .then(()=>{
                          caso.status = event.target.value;
                          caso.statusMensaje = event.target.value;
                      })
                      .catch(e => {
                          console.log(e);
                      });



                      firebase.database().ref('gastos_aprobados/' + caso.responsable_dni+'/'+caso.fireId).update({
                          status: event.target.value,

                      })
                      .then(()=>{
                         
                      })
                      .catch(e => {
                          console.log(e);
                      });
            

            $("#historial").empty();
            }else if (event.target.value == "Editar") {
                window.$('.editar_modal').modal();
                localStorage.setItem("status_id", caso.fireId);
                localStorage.setItem("status_sms", event.target.value);


            } else if (event.target.value == "") {


            }else{
         
                      firebase.database().ref('novedades_usuarios_test_hrbp/' + caso.fireId).update({
                          status: event.target.value,

                      })
                      .then(()=>{
                          caso.status = event.target.value;
                          caso.statusMensaje = event.target.value;
                      })
                      .catch(e => {
                          console.log(e);
                      });

        const addZero =(i) => {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        }

        var h = new Date();
                var hora = addZero(h.getHours());
                var minutos = ("0" + h.getMinutes()).substr(-2);
                var segundos = ("0" + h.getSeconds()).substr(-2);
                var horaActual = hora +':'+ minutos +':'+ segundos;


                      firebase.database().ref('gastos_aprobados/'+caso.responsable_dni+'/'+caso.fireId).update({
                                  fecha: caso.fecha,
                                  
                                  fecha_novedad:caso.fecha_novedad,
                                  responsable: caso.responsable,
                                  operacion_raiz:caso.operacion_raiz,
                                  legajo: caso.legajo,
                                  nombre: caso.nombre,
                                  id_novedad: caso.bejerman_id,
                                  novedad: caso.novedad,
                                  bejerman_id: caso.bejerman_id,
                                  detalle: caso.detalle,
                                  status: event.target.value,
                                  status_hora:horaActual,
                                  gastos:caso.gastos,
                                  desde:caso.desde,
                                  hasta:caso.hasta,
                                  checking:1,
                                  responsable_dni:caso.responsable_dni,
                                  certificado:caso.certificado,
                                  horas:'-'
                                 }, function (error) {

                                  if (error) {
                                    console.log("Ocurrio un error! verifique su conexión a internet!")
                                  } else {
                                    console.log("gastos cargados en novedades rrhh")
                                  }

                                });


            }    
      },

      mensajes(){


            window.$('.modal').modal();
            $("#historial").empty();

                 axios.get("https://aplicativos.firebaseio.com/novedades_usuarios_test_hrbp.json?orderBy=%22$key%22&equalTo=%22"+localStorage.getItem('status_id')+"%22&print=pretty")
                .then((response) => {

                    $.each(response.data,function(key,value){
                        $.each(value.observacion,function(k,v){
                            $("#historial").prepend("<center><div style='width:90%;padding:5px;border:1px solid #d2cbcb;margin-top:5px;margin-bottom:5px;margin:5px;text-align: left;padding-left: 15px;'><span style='color:#716868;'><i>"+v.timestamp+"</i></span><br><b>"+v.nombre+":</b> "+v.detalle+"<br></div></center>");
                        })
                    })


                });
                     


          
      },

      guardaObservacion(){



        const addZero =(i) => {
            if (i < 10) {
                i = "0" + i;
            }
            return i;
        }

                var h = new Date();
                var hora = addZero(h.getHours());
                var minutos = ("0" + h.getMinutes()).substr(-2);
                var segundos = ("0" + h.getSeconds()).substr(-2);
                var horaActual = hora +':'+ minutos +':'+ segundos;

                var today = new Date();
                var m = today.getMonth() + 1;
                var mes = m < 10 ? "0" + m : m;
                var dia = today.getDate();
                dia = dia < 10 ? "0" + dia : dia;
                var fechaActual = today.getFullYear() + "-" + mes + "-" + dia;
                var timestamp = fechaActual + " " + horaActual;


           
                firebase.database().ref('novedades_usuarios_test_hrbp/' + localStorage.getItem('status_id')+'/observacion').push({
                    detalle: $("#observacion").val(),
                    timestamp:timestamp,
                    nombre:userData.leg_nombre+' '+userData.leg_apellido

                })
                .then(()=>{


                        firebase.database().ref('novedades_usuarios_test_hrbp/' + localStorage.getItem('status_id')).update({
                          status: localStorage.getItem('status_sms')

                        })
                        .then(()=>{
                         
                        })
                        .catch(e => {
                          console.log(e);
                        });

                  localStorage.removeItem('status_id');
                  $("#observacion").val("");

                    Swal.fire(
                                      "Observación creada correctamente!",
                                      "Su observación fue enviada al zonal que corresponda",
                                      "success"
                                    );

                                      setTimeout(function(){
                                          $("#cerrar_modal").click();
                                          //window.location.reload();
                                            
                                      },1000);

                })
                .catch(e => {
                    Swal.fire(
                                      "Ups!, Ocurrio un error",
                                      "Revise su conexión a internet y vuelva a intentar",
                                      "error"
                                    );

                                      setTimeout(function(){
                                          $("#cerrar_modal").click();
                                      },1000);
                });


              
      },

      

    },
  async mounted() {
     

            this.getNovedad();

           

          

          
              
              const axiosrequest1 = axios.get('https://aplicativos.firebaseio.com/distribucion.json?orderBy=%22$key%22&equalTo=%22999991%22&print=pretty');

              const axiosrequest2 = axios.get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567');

          await axios.all([axiosrequest1, axiosrequest2]).then(axios.spread((zonales, personal) => { 
          
                  var zonal = [];
                  var personal_data = personal.data;



                  const fecha = (dias) =>{


                      var date_min = new Date();
                      date_min.setDate(date_min.getDate() - dias);
                      var mea_min = date_min.getMonth() + 1;
                      var mesea_min = mea_min < 10 ? "0" + mea_min : mea_min;
                      var diaea_min = date_min.getDate();
                      diaea_min = diaea_min < 10 ? "0" + diaea_min : diaea_min;
                      var fecha_min = date_min.getFullYear() + "-" + mesea_min + "-" + diaea_min;

                      $("#fecha-"+dias).html(fecha_min);

                      return fecha_min;

                  }


                  const result_personal = (legajo) => {

                      var personal = "";
                      $.each(personal_data, function(clave, value) {
                            
                          if (value.leg_numero == legajo) {

                              personal = value.leg_numdoc;
                          }
                      })
                      return personal;
                  }


                   const verificar = (dni,dias) => {
                      
                      axios.get("https://auth.foodservice.com.ar/?type=novedades_check&fecha="+fecha(dias)+"&responsable_dni="+dni+"&access_token=1234567")
                        .then((response) => {
                            
                            if (response.data==null) {
                              $("#dni-"+dni+"-"+dias).removeClass("badge-success")
                              $("#dni-"+dni+"-"+dias).addClass("badge-danger")
                            }
                           


                        });

                 
                     
                        
                  }

                   $.each(zonales.data,function(key,value){
                       $.each(value,function(k,v){
                          
                          zonal.push({"legajo":v.legajo,"nombre":v.nombre,"dni":result_personal(v.legajo)});
                          verificar(result_personal(v.legajo),1)
                          verificar(result_personal(v.legajo),2)
                          verificar(result_personal(v.legajo),3)
                          verificar(result_personal(v.legajo),4)

                      })
                    })
                    


            

                
                this.zonales = zonal;

   })).then(() => {});


  }
}
</script>


<style type="text/css">
  .imprimir{
    color: #212529!important;
    background-color: #fbbc06!important;
    border-color: #fbbc06!important;
}
</style>