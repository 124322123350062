<template>
    <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <div class="modal-header align-items-center no-gutters" v-if="!triggers.success">
                            <div class="col-10">Resolución de caso {{ caso.nombre }} | {{ caso.fecha }} {{ caso.hora }}</div>
                            <div class="col-2 text-right">
                                <button type="button" class="btn btn-sm btn-light font-weight-bold" @click.prevent="close()"> x </button>
                            </div>
                        </div>
                        <div class="modal-body m-0" v-if="triggers.success">
                            <h5 class="text-success text-center mt-1">✓ {{triggers.successMessage}}</h5>
                        </div>
                        <!-- Success title -->
                        <div class="modal-body" v-if="!triggers.success">
                            <label class="form-label mt-2">Observación</label>
                            <div class="border border-light rounded bg-light text-muted p-3 mb-3 f-size-8">
                                {{caso.observacion}}
                            </div>
                            <label for="listaEmpleados" class="form-label mt-2">Agregar una resolución</label>
                            <!-- Input -->
                            <textarea v-if="isEditor('Orientacion')==true" class="w-100 form-control" v-model="resolucion" @change="errors.resolucion = false" rows="4"></textarea>

                            <textarea v-else class="w-100 form-control" v-model="resolucion" @change="errors.resolucion = false" rows="4" disabled="true"></textarea>


                            <p class="text-danger my-2" v-if="errors.resolucion">· Lo observación no puede estar vacia</p>
                            <p class="text-danger my-2" v-if="errors.resolucion">· Para eliminarla usá el botón "quitar observacion"</p>
                        </div>
                        <!-- Buttons footer -->
                        <div class="modal-footer" v-if="!triggers.success">
                            <button v-if="isEditor('Orientacion')==true"
                                type="button"
                                @click.prevent="agregarResolución()"
                                class="btn btn-success"
                            >
                                Guardar resolución
                            </button>
                             <button v-else type="button" class="btn btn-secondary btn-xs" disabled>Guardar resolución</button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
</template>

<script>
import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));

export default {
    name: "ModalDetalle",
    props: ['caso'],
    data(){
        return {
            errors: {
                resolucion: false 
            },
            triggers: {
                success: false,
                successMessage: null,
                dangerMessage: null,
            },
            resolucion: null,
            seObservo: false,
        }
    },
    mounted(){
            this.resolucion = this.caso.resolucion;
    },
    methods: {
         isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
        agregarResolución(){
            this.limpiarTriggers();
            if (this.resolucion == '') {
                this.errors.resolucion = true;
                return null;
            }else {
                // console.log('orientacion-b/' + this.caso.fireId);                
                firebase.database().ref('orientacion-b/' + this.caso.fireId).update({
                    resolucion: this.resolucion
                }).then(()=>{
                    console.log("insertado ok");
                    this.triggers.successMessage = "Observacion realizada correctamente";
                    this.triggers.success = true;
                    this.seObservo = true;
                    setTimeout(() => {
                        this.close();
                    }, 1000);
                })
                .catch((error)=>{
                    if(error!=null){
                        alert("Datos no cargados! verifique su conexión a internet")
                    }
                });
            }
        },
        limpiarTriggers(){
            this.success = false;
            this.successMessage = null;
            this.dangerMessage = null;
        },
        close(){
            this.$emit('cerrar-modal-detalle', this.seObservo);
        },

    }
}
</script>

<style scoped>
h3 {
    color: #1ea21e;
}

.f-size-8 {
    font-size: .8rem;
}

.input-error {
    border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    width: 50%;
    margin: 0px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 2px 8px #00000016;
    transition: all .3s ease;
    font-size: 16px;

    /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
    margin-bottom: 1rem;
}

input, select {
    font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>