<template >
      <div id="app" v-if="logged==true">
        <div class="main-wrapper">
          <Menu/>
          <div class="page-wrapper">
            <Navbar/>
            <router-view></router-view>
          </div>
        </div>
      </div>
      <div v-else>
          <router-view></router-view>
      </div>
</template>
<script>

import axios from 'axios';

import Menu from './components/Menu/Menu.vue'
import Navbar from './components/Navbar/Navbar.vue'

import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import Swal from 'sweetalert2';



localStorage.removeItem("__personal")
localStorage.removeItem("__personal_cat")

const firebaseConfig = {
  // Produccion
  apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
  authDomain: "cantina-89c6a.firebaseapp.com",
  databaseURL: "https://aplicativos.firebaseio.com",
  projectId: "cantina-89c6a",
  storageBucket: "cantina-89c6a.appspot.com",
  messagingSenderId: "353062217145",
  appId: "1:353062217145:web:8849a49a1ec52077"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const fireMantenimiento = firebase.initializeApp({
	  // Prod
	  apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
    authDomain: "cantina-89c6a.firebaseapp.com",
    databaseURL: "fs-mantenimiento.firebaseio.com",
    projectId: "cantina-89c6a",
    storageBucket: "cantina-89c6a.appspot.com",
    messagingSenderId: "353062217145",
    appId: "1:353062217145:web:8849a49a1ec52077"

	  // Dev
    // apiKey: "AIzaSyA6ToO8M7vBH4LFuWafjaApqEXWGHYqjFQ",
    // authDomain: "fs-mantenimiento-dev-d4419.firebaseapp.com",
    // databaseURL: "https://fs-mantenimiento-dev-d4419-default-rtdb.firebaseio.com",
    // projectId: "fs-mantenimiento-dev-d4419",
    // storageBucket: "fs-mantenimiento-dev-d4419.appspot.com",
    // messagingSenderId: "790417932750",
    // appId: "1:790417932750:web:15ec6cf8329a8b23826b9f"
  },
  "fireMantenimiento"
);

const fireRRHH = firebase.initializeApp({
  // Prod
  apiKey: "AIzaSyBlC9ejdWKNwWDbBUCCSZsg-4Epb1K9Bfc",
  authDomain: "apprrhh-707b9.firebaseapp.com",
  databaseURL: "https://apprrhh-707b9.firebaseio.com",
  projectId: "apprrhh-707b9",
  storageBucket: "apprrhh-707b9.appspot.com",
  messagingSenderId: "697935364592",
  appId: "1:697935364592:web:3c35c8dbb6b90039"

  // Dev
  // apiKey: "AIzaSyC_hpdpOdzvfmuQusITAZB2UmXurjAfLwk",
  // authDomain: "fs-apprrhh-dev.firebaseapp.com",
  // databaseURL: "https://fs-apprrhh-dev-default-rtdb.firebaseio.com",
  // projectId: "fs-apprrhh-dev",
  // storageBucket: "fs-apprrhh-dev.appspot.com",
  // messagingSenderId: "479731158043",
  // appId: "1:479731158043:web:9b4b941bfe17b43d47bcbe"


}, 'fireRRHH');

const userData = JSON.parse(sessionStorage.getItem('userData'));
let plataforma = "Genesis";


export default {
    name: 'app',
    components:{
       
          Menu,
          Navbar,

          
  },
    data: () => ({
      logged: false,
    }),
    methods: {
          isLector(modulo){
            
                if (JSON.parse(sessionStorage.getItem('userPermisos')).find( record => record.ModulosAccess === modulo)) {
                  var status =  JSON.parse(sessionStorage.getItem('userPermisos')).find( record => record.ModulosAccess === modulo).Lector;
                  // console.log(modulo+' : '+status)
                  return status;
                }else{
                  // console.log(modulo+' : '+false)
                  return false;
                }
            },
      isLogged() {


        /* VERIFICA QUE SEA 1 O 16 DEL MES */

       

        var hoy = new Date();

        var mesh = hoy.getMonth() + 1;
        var meshoy = mesh < 10 ? "0" + mesh : mesh;
        var diahoy= hoy.getDate();
        diahoy = diahoy < 10 ? "0" + diahoy : diahoy;
        var hoy_dato = hoy.getFullYear() + "-" + meshoy + "-" + diahoy;
        console.log("fecha de hoy")
     

        if (parseInt(diahoy)==1||parseInt(diahoy)==16) {
          console.log(parseInt(diahoy))
          if (localStorage.getItem("auditadas_data")==0) {
            firebase.database().ref('auditadas_data/').remove();
          }

          localStorage.setItem("auditadas_data",1)
        }else{
          localStorage.setItem("auditadas_data",0)
        }

        /* CIERRA VERIFICACION */

        userData
          ? this.logged = true
          : this.logged = false

          if (this.logged) {
            sessionStorage.clear();
            this.$router.push({ path: '/' })





                


                            axios
                        .get('https://auth.foodservice.com.ar/?type=login&user='+userData.leg_numdoc+'&access_token=1234567')
                        .then(response => {
                            // console.log("ok")
                           sessionStorage.setItem('userData', JSON.stringify(response.data[0].datos))
                              sessionStorage.setItem('userPermisos', JSON.stringify(response.data[0].Permisos))
                            
                             sessionStorage.setItem('userId', response.data[0].id)
                             //sessionStorage.setItem('p_img', response.data[0].Perfil[0].imagen)
                              sessionStorage.setItem("em", response.data[0].datos.leg_numero);
                              sessionStorage.setItem('manipulacion', response.data[0].Manipulador[0].link)
                              // console.log("actualizacion")
                              const uId = sessionStorage.getItem('userId');
                            if (this.isLector(plataforma)==false) {
                              Swal.fire('Acceso denegado', 'No tienes los permisos necesarios para acceder a Genesis','error');
                              sessionStorage.clear();
                              location.reload();
                            }
                            
                             var correop = "";
                             var oculto ="";
                            
                             if (response.data[0].Email_de_recupero[0].correo!=null) {
                                correop = response.data[0].Email_de_recupero[0].correo;
                                oculto ="style='display:none;'";
                             }

                            //  console.log(response.data[0].Password[0].contrasena)

                             if (response.data[0].Password[0].contrasena==null) {

                                     (async function(){

                                           await Swal.fire({
                                            title: 'Ingrese una nueva contraseña',
                                            html:
                                            '<form action="#" id="searchForm">'+
                                              '<input required type="email" id="correo1" class="swal2-input" placeholder="Email de recupero" value="'+correop+'" '+oculto+' autocomplete="off"><span style="color:red;font-size:14px;display:none;" id="errormail">Email no coincide</span><input autocomplete="off" required type="email" id="correo2" class="swal2-input" placeholder="Repita Email de recupero" value="'+correop+'" '+oculto+'><input autocomplete="off" required type="password" id="pass1" class="swal2-input" placeholder="Nueva contraseña"  ><span style="color:red;font-size:14px;display:none;" id="errorpass">Password no coincide</span><input autocomplete="off" required type="password" id="pass2" class="swal2-input" placeholder="Repita Nueva contraseña"><button type="button" id="guardar" class="swal2-confirm swal2-styled" aria-label="" style="display: inline-block;">Guardar</button></form>',
                                               showCancelButton: false,   
                                               showConfirmButton: false, 
                                                didOpen(){
                                                  window.$("#guardar").click(function(){
                                                     

                                                    if(window.$("#correo1").val()=="") {
                                                      // console.log("vacio")
                                                    }else if(window.$("#correo2").val()=="") {
                                                      // console.log("vacio")
                                                    }else if(window.$("#pass1").val()=="") {
                                                      // console.log("vacio")
                                                    }else if(window.$("#pass2").val()=="") {
                                                      // console.log("vacio")
                                                    }else{

                                                      var correo1 = window.$("#correo1").val();
                                                      var correo2 = window.$("#correo2").val();

                                                      var pass1 = window.$("#pass1").val();
                                                      var pass2 = window.$("#pass2").val();

                                                      if (correo1!=correo2) {
                                                        window.$("#errormail").slideDown();
                                                      }else if(pass1!=pass2){
                                                        window.$("#errorpass").slideDown();
                                                      }else{
                                                        
                                                       

                                                        window.$("#errorpass").hide();
                                                        window.$("#errormail").hide();
                                                        var info;

                                                        if (correop==null) {



                                                            firebase.database().ref('password/' + uId +'').update({
                                                              contrasena:pass1

                                                            })
                                                            .then(()=>{










                                                         firebase.database().ref('Email_de_recupero/' + uId +'').update({
                                                                                  correo:correo1

                                                                                })
                                                                                .then(()=>{
                                                                                  Swal.close();
                                                                                  Swal.fire('Datos Guardados', 'Felicitaciones!, ya puedes utilizar '+plataforma,'success');
                                                                                })
                                                                                .catch(e => {
                                                                                  console.log(e);
                                                                                });


                                                             
                                                            })
                                                            .catch(e => {
                                                              console.log(e);
                                                            });

                                                         

                                                        }else{

                                                           firebase.database().ref('password/' + uId +'').update({
                                                              contrasena:pass1

                                                            })
                                                            .then(()=>{


                                                              Swal.close();
                                                                                  Swal.fire('Datos Guardados', 'Felicitaciones!, ya puedes utilizar '+plataforma,'success');


                                                             
                                                            })
                                                            .catch(e => {
                                                              console.log(e);
                                                            });


                                                        }

                                                 }

                                                        if (correo1==correo2) {
                                                          window.$("#errormail").hide();
                                                        }

                                                        if(pass1==pass2){
                                                          window.$("#errorpass").hide();
                                                        }


                                                    }

                                                    
                                                  })
                                               }
                                           


                                            })

                                    })();



                             }
                          
                               
                            })
                        .catch(error => {console.log(error);});




                             axios
                        .get('https://auth.foodservice.com.ar/?type=personal&access_token=1234567')
                        .then(response => {

                             // localStorage.setItem("personal",JSON.stringify(response.data))
                             $.each(response.data, (ids, items) => {
                            
                                 localStorage.setItem(items.leg_numero, items.cat_codigo)
                             })
                             
                            
                      });



                       axios
                        .get('https://aplicativos.firebaseio.com/categorias.json')
                        .then(response => {


                             $.each(response.data, (ids, items) => {
                            
                                 localStorage.setItem("cat-"+items.codigo, items.nombre)
                                 localStorage.setItem(items.nombre, items.codigo)
                             })
                             
                            
                      });


           setTimeout(function(){
            $("#correo1").val("");
            $("#pass1").val("");
           },1000)


           


          }else{
            this.$router.push('/Login')
          }
      },
      logout() {
        sessionStorage.removeItem('userData')
       

        this.$router.push('/login')
      },
     
    },
    computed: {
      loggedUser() {
        const token = sessionStorage.getItem('userData')
        const payload = JSON.parse(atob(token.split('.')[1]))
        // console.log(payload)
        return payload
      }
    },
    mounted() {
      this.isLogged()




      // axios.get('https://aplicativos.firebaseio.com/novedades_usuarios_test.json?orderBy=%22fecha%22&startAt=%222021-03-09%2008:27:02%22&limitToLast=2000&print=pretty').then((data)=>{
      //   localStorage.setItem('novedades',JSON.stringify(data.data));
      //   console.log('nodedades obtenidas');
      // })

      let novedades = JSON.parse(localStorage.getItem('novedades'));
      let nObject = {}
      if (novedades!=null) {
        Object.keys(novedades).forEach((id)=>{
          if (nObject[novedades[id]['novedad']] == undefined) {
            nObject[novedades[id]['novedad']] = {
              label: novedades[id]['novedad'],
              type: (novedades[id]['horas'] === '-') ? 'dias' : 'horas',
            }
          }
        });
      }

      // users.sort((a, b) => a.firstname.localeCompare(b.firstname))

      // console.log(nObject);

    }
  };


</script>

